import Vue from "vue";
import VueRouter from "vue-router";
import Layouts from "../layouts";

Vue.use(VueRouter);

/**
 *  path: "/login",   ------页面地址
 meta: {
      title: "登录",    ------页面标题
      icon: "el-icon-user-solid",  ------菜单图标
      oneself: true,  ------是否在单独页面打开
      hide: true,  ------是否隐藏改菜单
    },
 component: () => import("../views/login/index.vue"),  ------组件地址
 */

const routes = [
  {
    path: "",
    redirect: "home",
    component: Layouts,
    children: [
      {
        path: "/login",
        name: "Login",
        meta: {
          title: "登录",
          oneself: true,
          hide: true,
        },
        component: () => import("../views/login"),
      },
      {
        path: "/home",
        meta: {
          title: "邀约任务管理",
          icon: "el-icon-receiving",
          keepAlive: true,
        },
        component: () => import("../views/home/index.vue"),
      },
      {
        path: "/detail",
        name: "HomeDetail",
        meta: {
          title: "邀约任务详情",
          hide: true,
        },
        component: () => import("../views/home/detail.vue"),
      },
      {
        path: "/datadetail",
        meta: { title: "工单管理", icon: "el-icon-document" },
        component: Layouts,
        children: [
          {
            path: "/index1",
            meta: { title: "工单分配" },
            component: () => import("../views/datadetail/index1"),
          },
          {
            path: "/assignment",
            name: "Assignment",
            meta: { title: "工单分配", hide: true },
            component: () => import("../views/datadetail/assignment.vue"),
          },
          {
            path: "/index2",
            meta: { title: "分配完成" },
            component: () => import("../views/datadetail/index2"),
            beforeEnter(to, from, next) {
              let token = localStorage.getItem("token");
              if (!token) return next("/login");
              next();
            },
          },
          {
            path: "/index3",
            meta: { title: "申诉管理" },
            component: () => import("../views/datadetail/index3"),
          },
        ],
      },
      {
        path: "/log",
        meta: { title: "私库管理", icon: "el-icon-house" },
        component: () => import("../views/systemsetting/logadmin/index.vue"),
      },
      {
        path: "/accout/index",
        meta: { title: "商户管理", icon: "el-icon-s-custom", keepAlive: true },
        component: () => import("../views/systemsetting/account/index.vue"),
      },
      {
        path: "/accout/detail",
        name: "AccountDetail",
        meta: { title: "商户详情", hide: true },
        component: () => import("../views/systemsetting/account/detail.vue"),
      },
      {
        path: "/systemsetting",
        meta: { title: "数据看板", icon: "el-icon-s-marketing" },
        component: Layouts,
        children: [
          {
            path: "/index",
            meta: { title: "邀约看板" },
            component: () =>
              import("../views/systemsetting/dataadmin/index.vue"),
          },
          {
            path: "index2",
            meta: { title: "商户看板" },
            component: () =>
              import("../views/systemsetting/dataadmin/index2.vue"),
          },
          {
            path: "index3",
            meta: { title: "资金看板" },
            component: () =>
              import("../views/systemsetting/dataadmin/index3.vue"),
          },
        ],
      },
    ],
  },
];
//个人信息
// export const updateUser = [
//   {
//     path: "/userportrait",
//     meta: { title: "个人信息", icon: "el-icon-s-home" },
//     component: () => import("../views/userportrait"),
//   },
// ];

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法，取消路由重复的报错
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function getToken() {
  return;
}

// 前置路由拦截器
router.beforeEach((to, from, next) => {
  const tokenStr = localStorage.getItem("token");
  if (to.path === "/login") {
    next();
  } else if (!tokenStr) {
    next("/login");
  }
  next();
});

export default router;
