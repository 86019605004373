/*
 * @Author: lihuaruiz lihuarui@huawei.com
 * @Date: 2024-06-03 13:27:29
 * @LastEditors: lihuaruiz lihuarui@huawei.com
 * @LastEditTime: 2024-06-29 16:17:17
 * @FilePath: \vue2Admin\src\utils\http.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 引入axiox
import axios from "axios";
// 创建axios实例
const service = axios.create({
  baseURL: "http://chesumai.cn:31211",
  withCredentials: false,
  timeout: 50000,
  headers: {},
});

// 请求拦截器
// axios.interceptors.request.use(
//   function (config) {
//     return config;
//   },
//   function (error) {
//     // 对请求错误做些什么
//     return Promise.reject(error);
//   }
// );

// 响应拦截器
// service.interceptors.response.use(
//   function (result) {
//     // 判断成功
//     const { status, data } = result;
//     // 判断接口返回状态
//     if (status === 200) {
//       // 如果接口正常则返回接口给的数据
//       return data;
//     } else {
//       // 如果不正常则返回一个错误信息
//       return Promise.reject("系统未知错误，请反馈给管理员");
//     }
//   },
//   function (error) {
//     // 返回错误信息
//     return Promise.reject(error);
//   }
// );

// 添加请求拦截器
// 拦截器的第一部分，第二部分在router index.js里面
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // 判断是否存在token,如果存在将每个页面header添加token
  if (window.localStorage.getItem("token")) {
    config.headers.common["Access-Token"] =
      window.localStorage.getItem("token");
  }
  return config;
});

export default service;
